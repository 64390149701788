import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import EmployeeCost from "./employee-cost";
import CommonDeduction from "./common-deduction";
import GrossSalary from "./gross-salary";
import EmployeeForm from "./employee-form";
import EmployerForm from "./employer-form";
import PDF from "./pdf";
import TaxBenefit from "./tax-benefit";
import IncomeType from "./income-type";
import { RootState } from "../app/store";
import Period from "./period";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Month from "./month";
import Days from "./days";

interface IProps {
  version: string;
  isEmployee: boolean;
}

interface IState {
  title: string;
  value: any;
  expanded: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

class Home extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      title: "",
      value: 0,
      expanded: "panel1",
    };
  }

  TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  render() {
    const { title, value, expanded } = this.state;
    const { isEmployee } = this.props;

    return (
      <>
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="Simulateur de paie" />
            <Tab label="Generer un pdf" />
          </Tabs>
        </AppBar>
        <this.TabPanel value={value} index={0}>
          <Grid container direction="row" spacing={2}>
            <Grid xs={6} item>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <IncomeType />
                </Grid>
                <Grid xs={12} item>
                  <CommonDeduction />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6} item>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <TaxBenefit />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} item>
              <GrossSalary />
            </Grid>
            {isEmployee ? (
              <Grid xs={4} item>
                <EmployeeCost />
              </Grid>
            ) : (
              <></>
            )}
            <Grid xs={12} item></Grid>
          </Grid>
        </this.TabPanel>
        <this.TabPanel value={value} index={1}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={6}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={() => this.setState({ expanded: "panel1" })}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2>Période</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Period />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={() => this.setState({ expanded: "panel2" })}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <h2>Entreprise</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <EmployerForm />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={() => this.setState({ expanded: "panel3" })}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <h2>Salarié</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <EmployeeForm />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={6}>
              <PDF />
            </Grid>
          </Grid>
        </this.TabPanel>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  isEmployee: state.gross.isEmployee,
});

export default connect(mapState)(Home);
