import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { getTaxBenefit } from "../app/fiscal-slice";
import { useAppSelector } from "../app/hooks";
import LifeInsurance from "./life-insurance";
import SavingAccountAction from "./saving-account-action";
import { RootState } from "../app/store";

const TaxBenefit = ({ limitTaxBenefit }: { limitTaxBenefit: number }) => {
  const taxBenefit = useAppSelector(getTaxBenefit);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <SavingAccountAction />
            <LifeInsurance />
            <TableRow>
              <TableCell component="th" scope="row">
                <strong>Total</strong>
              </TableCell>
              <TableCell scope="row" align="right">
                <strong>
                  <NumberFormat
                    value={taxBenefit}
                    displayType="text"
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                  />
                </strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Limite effective</TableCell>
              <TableCell align="right">
                <NumberFormat
                  value={limitTaxBenefit}
                  displayType="text"
                  thousandSeparator
                  decimalScale={3}
                  fixedDecimalScale
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  csRate: state.pdf.pd.csRate,
  limitTaxBenefit: state.fiscal.limitTaxBenefit,
});

export default connect(mapStateToProps)(TaxBenefit);
