// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (message) => {
    const { totalBrut, cnssRate, cavisRate, atRate, tfpRate, forprolosRate } =
      message.data;

    const cnss = totalBrut * cnssRate;
    const cavis = totalBrut * cavisRate;
    const at = totalBrut * atRate;
    const tfp = totalBrut * tfpRate;
    const foporlos = totalBrut * forprolosRate;
    const totalAnnual = cnss + cavis + at + tfp + foporlos;

    const result = {
      cnss,
      cavis,
      at,
      tfp,
      foporlos,
      totalAnnual,
      base: totalBrut,
    };

    postMessage(result);
  };
};
