import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Dispatch } from "redux";
import React from "react";
import { connect } from "react-redux";
import { updateMonth } from "../app/pdf-slice";
import { RootState } from "../app/store";

const MonthPicker = ({
  period,
  updateMonth,
}: {
  period: { month: string };
  updateMonth: (value: string) => void;
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="month-label">Mois</InputLabel>
      <Select
        labelId="month-label"
        id="month"
        value={period.month}
        onChange={(value) => updateMonth(value.target.value as string)}
      >
        <MenuItem value={"Janvier"}>Janvier</MenuItem>
        <MenuItem value={"Février"}>Février</MenuItem>
        <MenuItem value={"Mars"}>Mars</MenuItem>
        <MenuItem value={"Avril"}>Avril</MenuItem>
        <MenuItem value={"Mai"}>Mai</MenuItem>
        <MenuItem value={"Juin"}>Juin</MenuItem>
        <MenuItem value={"Juillet"}>Juillet</MenuItem>
        <MenuItem value={"Aout"}>Août</MenuItem>
        <MenuItem value={"Septembre"}>Septembre</MenuItem>
        <MenuItem value={"Octobre"}>Octobre</MenuItem>
        <MenuItem value={"Novembre"}>Novembre</MenuItem>
        <MenuItem value={"Décembre"}>Décembre</MenuItem>
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state: RootState) => ({
  period: state.pdf.period,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateMonth: (value: string) => dispatch(updateMonth(value)),
});

export default connect(mapStateToProps, mapDispatch)(MonthPicker);
