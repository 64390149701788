import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

type Child = {
  id: number;
  is: boolean;
  isDisabled: boolean;
  isScholarshipHolder: boolean;
};

// Define a type for the slice state
interface FiscalState {
  dependentChildren: Child[];
  isLeader: boolean;
  isProfessionalFees: boolean;
  isSavingAccountAction: boolean;
  savingAccountAction: number;
  isLifeInsurance: boolean;
  lifeInsurance: number;
  limitTaxBenefit: number;
}

// Define the initial state using that type
const initialState: FiscalState = {
  dependentChildren: [
    {
      id: 1,
      is: false,
      isDisabled: false,
      isScholarshipHolder: false,
    },
    {
      id: 2,
      is: false,
      isDisabled: false,
      isScholarshipHolder: false,
    },
    {
      id: 3,
      is: false,
      isDisabled: false,
      isScholarshipHolder: false,
    },
    {
      id: 4,
      is: false,
      isDisabled: false,
      isScholarshipHolder: false,
    },
  ],
  isLeader: false,
  isProfessionalFees: true,
  isSavingAccountAction: false,
  savingAccountAction: 0,
  isLifeInsurance: false,
  lifeInsurance: 0,
  limitTaxBenefit: 0,
};

export const fiscalSlice = createSlice({
  name: "fiscal",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateChildren: (state, action: PayloadAction<Child>) => {
      state.dependentChildren = state.dependentChildren.map((row) => {
        if (row.id === action.payload.id) {
          return action.payload;
        }
        return row;
      });
    },
    updateIsLeader: (state, action: PayloadAction<boolean>) => {
      state.isLeader = action.payload;
    },
    updateProfessionalFees: (state, action: PayloadAction<boolean>) => {
      state.isProfessionalFees = action.payload;
    },
    updateIsSavingAccountAction: (state, action: PayloadAction<boolean>) => {
      state.isSavingAccountAction = action.payload;
    },
    updateSavingAccountAction: (state, action: PayloadAction<number>) => {
      state.savingAccountAction = action.payload;
    },
    updateIsLifeInsurance: (state, action: PayloadAction<boolean>) => {
      state.isLifeInsurance = action.payload;
    },
    updateLifeInsurance: (state, action: PayloadAction<number>) => {
      state.lifeInsurance = action.payload;
    },
    updateLimitTaxBenefit: (state, action: PayloadAction<number>) => {
      state.limitTaxBenefit = action.payload;
    },
  },
});

export const {
  updateChildren,
  updateProfessionalFees,
  updateIsLeader,
  updateIsSavingAccountAction,
  updateSavingAccountAction,
  updateIsLifeInsurance,
  updateLifeInsurance,
  updateLimitTaxBenefit,
} = fiscalSlice.actions;

export const getDependentChildren = (state: RootState) => {
  return state.fiscal.dependentChildren;
};

export const getIsProfessionalFees = (state: RootState) => {
  return state.fiscal.isProfessionalFees;
};

export const getIsLeader = (state: RootState) => {
  return state.fiscal.isLeader;
};

export const getTaxBenefit = (state: RootState) => {
  if (state.fiscal.isSavingAccountAction && state.fiscal.isLifeInsurance) {
    return state.fiscal.savingAccountAction + state.fiscal.lifeInsurance;
  }

  if (state.fiscal.isSavingAccountAction && !state.fiscal.isLifeInsurance) {
    return state.fiscal.savingAccountAction;
  }

  if (!state.fiscal.isSavingAccountAction && state.fiscal.isLifeInsurance) {
    return state.fiscal.lifeInsurance;
  }

  return 0;
};

export const getIsSavingAccountAction = (state: RootState) => {
  return state.fiscal.isSavingAccountAction;
};

export const getIsLifeInsurance = (state: RootState) => {
  return state.fiscal.isLifeInsurance;
};

export type { FiscalState };
export default fiscalSlice.reducer;
