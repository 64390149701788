import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  updateCenterCostId,
  updateChildren,
  updateCinId,
  updateCsId,
  updateFirstName,
  updateInternalId,
  updateJobTitle,
  updateLastName,
  updateLeaveBalance,
  updateMaried,
  updatePaymentMethod,
} from "../app/pdf-slice";
import { RootState } from "../app/store";

const EmployeeForm = ({
  employee,
  updateFirstName,
  updateLastName,
  updateInternalId,
  updateCsId,
  updateCinId,
  updateCenterCostId,
  updateJobTitle,
  updateMaried,
  updateLeaveBalance,
  updatePaymentMethod,
}: {
  employee: {
    firstName: string;
    lastName: string;
    internalId: string;
    csId: string;
    cinId: string;
    centerCostId: string;
    jobTitle: string;
    maried: string;
    children: number;
    leaveBalance: number;
    paymentMethod: string;
  };
  updateFirstName: (value: string) => void;
  updateLastName: (value: string) => void;
  updateInternalId: (value: string) => void;
  updateCsId: (value: string) => void;
  updateCinId: (value: string) => void;
  updateCenterCostId: (value: string) => void;
  updateJobTitle: (value: string) => void;
  updateMaried: (value: string) => void;
  updateLeaveBalance: (value: number) => void;
  updatePaymentMethod: (value: string) => void;
}) => {
  return (
    <Grid container spacing={1} direction="column">
      <Grid item xs={12}>
        <TextField
          label="Nom"
          type="string"
          defaultValue={employee.lastName}
          fullWidth
          onChange={(values) => updateFirstName(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Prénom"
          type="string"
          defaultValue={employee.firstName}
          fullWidth
          onChange={(values) => updateLastName(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Matricule interne"
          type="string"
          defaultValue={employee.internalId}
          fullWidth
          onChange={(values) => updateInternalId(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Matricule CNSS"
          type="string"
          defaultValue={employee.csId}
          fullWidth
          onChange={(values) => updateCsId(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="N° CIN"
          type="string"
          defaultValue={employee.cinId}
          fullWidth
          onChange={(values) => updateCinId(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Centre de coût"
          type="string"
          defaultValue={employee.centerCostId}
          fullWidth
          onChange={(values) => updateCenterCostId(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Fonction"
          type="string"
          defaultValue={employee.jobTitle}
          fullWidth
          onChange={(values) => updateJobTitle(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="situation-familiale-label">
            Situation familiale
          </InputLabel>
          <Select
            labelId="situation-familiale-label"
            id="situation-familiale"
            value={employee.maried}
            onChange={(value) => updateMaried(value.target.value as string)}
          >
            <MenuItem value={"Célibataire"}>Célibataire</MenuItem>
            <MenuItem value={"Marié"}>Marié</MenuItem>
            <MenuItem value={"Divorcé"}>Divorcé</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Solde de congé"
          type="number"
          defaultValue={employee.leaveBalance}
          fullWidth
          onChange={(values) => updateLeaveBalance(Number(values.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="payment-method-label">Mode de paiement</InputLabel>
          <Select
            labelId="payment-method-label"
            id="payment-method"
            value={employee.paymentMethod}
            onChange={(value) =>
              updatePaymentMethod(value.target.value as string)
            }
          >
            <MenuItem value={"Virement"}>Virement</MenuItem>
            <MenuItem value={"Chéque"}>Chéque</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  employee: state.pdf.employee,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateFirstName: (value: string) => dispatch(updateFirstName(value)),
  updateLastName: (value: string) => dispatch(updateLastName(value)),
  updateInternalId: (value: string) => dispatch(updateInternalId(value)),
  updateCsId: (value: string) => dispatch(updateCsId(value)),
  updateCinId: (value: string) => dispatch(updateCinId(value)),
  updateCenterCostId: (value: string) => dispatch(updateCenterCostId(value)),
  updateJobTitle: (value: string) => dispatch(updateJobTitle(value)),
  updateMaried: (value: string) => dispatch(updateMaried(value)),
  updateLeaveBalance: (value: number) => dispatch(updateLeaveBalance(value)),
  updatePaymentMethod: (value: string) => dispatch(updatePaymentMethod(value)),
});

export default connect(mapStateToProps, mapDispatch)(EmployeeForm);
