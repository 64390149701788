import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { getDependentChildren, updateChildren } from "../app/fiscal-slice";
import { updateChildren as updateSumChildren} from '../app/pdf-slice';
import { useAppDispatch, useAppSelector } from "../app/hooks";

type Child = {
  id: number;
  is: boolean;
  isDisabled: boolean;
  isScholarshipHolder: boolean;
};

const DependentChildren = () => {
  const dispatch = useAppDispatch();
  const dependentChildren = useAppSelector(getDependentChildren);

  const calc = (child: Child) => {
    if (child.is && !child.isDisabled && !child.isScholarshipHolder) {
      return 100;
    }
    if (child.is && child.isDisabled && !child.isScholarshipHolder) {
      return 2000;
    }
    if (child.is && !child.isDisabled && child.isScholarshipHolder) {
      return 1000;
    }
    if (child.is && child.isDisabled && child.isScholarshipHolder) {
      return 2000;
    }
    return 0;
  };



  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{}</TableCell>
          <TableCell>à Charge</TableCell>
          <TableCell>Infirme</TableCell>
          <TableCell>Étudiant non boursier</TableCell>
          <TableCell align="right">Annuel</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dependentChildren.map((row: Child) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              Enfant
            </TableCell>
            <TableCell component="th" scope="row">
              <Checkbox
                color="primary"
                checked={row.is}
                onChange={(arg) => {
                  dispatch(updateChildren({ ...row, is: !row.is }));
                  let sum = dependentChildren.reduce(
                    (a: number, b: Child) => {
                      if(b.is) {
                        return a +1
                      }
                      return a
                    },
                    0
                  );
                  if(!row.is) {
                   sum += 1;
                  } else {
                    sum -= 1;
                  }
                  dispatch(updateSumChildren(sum));
                }}
              />
            </TableCell>
            <TableCell component="th" scope="row">
              <Checkbox
                color="primary"
                checked={!row.is ? false : row.isDisabled}
                disabled={!row.is}
                onChange={(arg) => {
                  dispatch(
                    updateChildren({ ...row, isDisabled: !row.isDisabled })
                  );
                }}
              />
            </TableCell>
            <TableCell component="th" scope="row">
              <Checkbox
                color="primary"
                checked={!row.is ? false : row.isScholarshipHolder}
                disabled={!row.is}
                onChange={(arg) => {
                  dispatch(
                    updateChildren({
                      ...row,
                      isScholarshipHolder: !row.isScholarshipHolder,
                    })
                  );
                }}
              />
            </TableCell>
            <TableCell align="right">
              <NumberFormat
                value={calc(row)}
                displayType="text"
                thousandSeparator
                decimalScale={3}
                fixedDecimalScale
              />
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={3} />
          <TableCell>Total</TableCell>
          <TableCell align="right">
            <NumberFormat
              value={dependentChildren.reduce(
                (a: number, b: Child) => a + calc(b),
                0
              )}
              displayType="text"
              thousandSeparator
              decimalScale={3}
              fixedDecimalScale
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DependentChildren;
