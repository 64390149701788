import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

type Gross = {
  id: number;
  title: string;
  value: number | string;
  isContribution: boolean;
  isImposition: boolean;
};

// Define a type for the slice state
interface GrossState {
  grossSalary: Gross[];
  bncIncome: Gross[];
  isEmployee: boolean;
}

// Define the initial state using that type
const initialState: GrossState = {
  grossSalary: [
    {
      id: 1,
      title: "Salaire de base",
      value: 0,
      isContribution: true,
      isImposition: true,
    },
  ],
  bncIncome: [
    {
      id: 1,
      title: "Bénéfice non commercial (BNC)",
      value: 0,
      isContribution: false,
      isImposition: true,
    },
  ],
  isEmployee: true,
};

export const grossSlice = createSlice({
  name: "gross",
  initialState,
  reducers: {
    add: (state) => {
      if (state.isEmployee) {
        state.grossSalary = [
          ...state.grossSalary,
          {
            id: state.grossSalary[state.grossSalary.length - 1].id + 1,
            title: "",
            value: 0,
            isContribution: true,
            isImposition: true,
          },
        ];
      } else {
        state.bncIncome = [
          ...state.bncIncome,
          {
            id: state.bncIncome[state.bncIncome.length - 1].id + 1,
            title: "",
            value: 0,
            isContribution: false,
            isImposition: true,
          },
        ];
      }
    },
    remove: (state, action: PayloadAction<number>) => {
      state.grossSalary = state.grossSalary.filter(
        (elem) => elem.id !== action.payload
      );
    },
    update: (state, action: PayloadAction<Gross>) => {
      if (state.isEmployee) {
        state.grossSalary = state.grossSalary.map((row) => {
          if (row.id === action.payload.id) {
            return action.payload;
          }
          return row;
        });
      } else {
        state.bncIncome = state.bncIncome.map((row) => {
          if (row.id === action.payload.id) {
            return action.payload;
          }
          return row;
        });
      }
    },
    updateBase: (state, action: PayloadAction<number>) => {
      state.grossSalary = state.grossSalary.map((row) => {
        if (row.id === 1) {
          return { ...row, value: action.payload };
        }
        return row;
      });
    },
    updateIsEmployee: (state, action: PayloadAction<boolean>) => {
      state.isEmployee = action.payload;
    },
    clearBase: (state) => {
      state.grossSalary = state.grossSalary.map((row) => {
        if (row.id === 1) {
          return { ...row, value: "" };
        }
        return row;
      });
    },
  },
});

export const { add, update, remove, updateIsEmployee, updateBase, clearBase } =
  grossSlice.actions;

export const grossTotal = (state: RootState) => {
  return state.gross.isEmployee
    ? state.gross.grossSalary.reduce((a: number, b: Gross) => {
        if (b.value && typeof b.value === "number") {
          return a + b.value;
        }
        return a;
      }, 0)
    : state.gross.bncIncome.reduce((a: number, b: Gross) => {
        if (b.value && typeof b.value === "number") {
          return a + b.value;
        }
        return a;
      }, 0);
};

export const grossTotalImposition = (state: RootState) => {
  return state.gross.isEmployee
    ? state.gross.grossSalary.reduce((a: number, b: Gross) => {
        if (b.isImposition && b.value && typeof b.value === "number") {
          return a + b.value;
        }
        return a;
      }, 0)
    : state.gross.bncIncome.reduce((a: number, b: Gross) => {
        if (b.isImposition && b.value && typeof b.value === "number") {
          return a + b.value;
        }
        return a;
      }, 0);
};

export const grossTotalContribution = (state: RootState) => {
  return state.gross.grossSalary.reduce((a: number, b: Gross) => {
    if (b.isContribution && b.value && typeof b.value === "number") {
      return a + b.value;
    }
    return a;
  }, 0);
};

export const getGrossSalary = (state: RootState) => {
  return state.gross.isEmployee
    ? state.gross.grossSalary
    : state.gross.bncIncome;
};

export const getProfessionalFees = (state: RootState) => {
  const grossTt = grossTotal(state);
  const grossTtContribution = grossTotalContribution(state);

  if ((grossTt - grossTtContribution) * 0.1 <= 2000) {
    return (grossTt - grossTtContribution) * 0.1;
  }
  return 2000;
};

export const getCavis = (state: RootState) => {
  const smig6 = 2476.8;
  const cavis = (grossTotalImposition(state) - smig6 * 12) * 0.03;
  return cavis > 0 ? cavis : 0;
};

export const getIsEmployee = (state: RootState) => {
  return state.gross.isEmployee;
};

export type { GrossState };
export default grossSlice.reducer;
