import { TableCell, TableRow, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { RootState } from "../app/store";
import { Dispatch } from "redux";
import { updateCsRate } from "../app/pdf-slice";

interface IProps {
  pd: { cs: number; csBase: number; csRate: number };
  updateCsRate: (value: number) => void;
}

const Contributions = ({ pd, updateCsRate }: IProps) => {
  const [focus, setFocus] = useState(false);
  const [mouseOut, setMouseOut] = useState(true);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        CNSS ({pd.csBase})
      </TableCell>
      <TableCell
        onMouseEnter={() => {
          setMouseOut(false);
        }}
        onMouseLeave={() => {
          if (!focus) {
            setMouseOut(true);
          } else {
            setMouseOut(true);
          }
        }}
      >
        {!mouseOut || focus ? (
          <TextField
            type="number"
            defaultValue={pd.csRate * 100}
            onChange={(values) =>
              updateCsRate(Number(values.target.value) / 100)
            }
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
          />
        ) : (
          <NumberFormat
            value={pd.csRate * 100}
            customInput={TextField}
            thousandSeparator
            decimalScale={3}
            fixedDecimalScale
            suffix="%"
          />
        )}
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        <NumberFormat
          value={Math.round(((pd.csBase * pd.csRate) / 12) * 1000) / 1000}
          displayType="text"
          thousandSeparator
          prefix="-"
          decimalScale={3}
          fixedDecimalScale
        />
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        <NumberFormat
          value={Math.round(((pd.csBase * pd.csRate)) * 1000) / 1000}
          displayType="text"
          thousandSeparator
          prefix="-"
          decimalScale={3}
          fixedDecimalScale
        />
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state: RootState) => ({
  pd: state.pdf.pd,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateCsRate: (value: number) => dispatch(updateCsRate(value)),
});

export default connect(mapStateToProps, mapDispatch)(Contributions);
