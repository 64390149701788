import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface CostState {
  CNSS: number;
  CAVIS: number;
  AT: number;
  TFP: number;
  FOPORLOS: number;
}

// Define the initial state using that type
const initialState: CostState = {
  CNSS: 16.57,
  CAVIS: 6.0,
  AT: 0.4,
  TFP: 2,
  FOPORLOS: 1,
};

export const costSlice = createSlice({
  name: 'cost',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateCNSS: (state, action: PayloadAction<number>) => {
      state.CNSS = action.payload;
    },
    updateCAVIS: (state, action: PayloadAction<number>) => {
      state.CAVIS = action.payload;
    },
    updateAT: (state, action: PayloadAction<number>) => {
      state.AT = action.payload;
    },
    updateTFP: (state, action: PayloadAction<number>) => {
      state.TFP = action.payload;
    },
    updateFOPORLOS: (state, action: PayloadAction<number>) => {
      state.FOPORLOS = action.payload;
    },
  },
});

export const {
  updateCNSS,
  updateCAVIS,
  updateAT,
  updateTFP,
  updateFOPORLOS,
} = costSlice.actions;

export type { CostState };
export default costSlice.reducer;
