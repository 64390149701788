import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { Dispatch } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../app/store";
import {
  updateAT,
  updateCAVIS,
  updateCNSS,
  updateFOPORLOS,
  updateTFP,
} from "../app/cost-slice";
import { grossTotal } from "../app/gross-slice";
import {
  updateECAtRate,
  updateECCavisRate,
  updateECCsRate,
  updateECForprolosRate,
  updateECTfpBase,
} from "../app/pdf-slice";

interface IProps {
  totalBrut: number;
  CNSS: number;
  CAVIS: number;
  AT: number;
  TFP: number;
  FOPORLOS: number;
  uCNSS: (value: number) => void;
  uCAVIS: (value: number) => void;
  uAT: (value: number) => void;
  uTFP: (value: number) => void;
  uFOPORLOS: (value: number) => void;
  updateECCsRate: (value: number) => void;
  updateECCavisRate: (value: number) => void;
  updateECAtRate: (value: number) => void;
  updateECTfpBase: (value: number) => void;
  updateECForprolosRate: (value: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {}

// eslint-disable-next-line react/prefer-stateless-function
class EmployeeCost extends React.Component<IProps, IState> {
  render() {
    const {
      totalBrut,
      CNSS,
      CAVIS,
      AT,
      TFP,
      FOPORLOS,
      uCNSS,
      uCAVIS,
      uAT,
      uTFP,
      uFOPORLOS,
      updateECCsRate,
      updateECAtRate,
      updateECTfpBase,
      updateECForprolosRate,
    } = this.props;

    const cnss = (totalBrut * CNSS) / 100;
    const cavis = (totalBrut * CAVIS) / 100;
    const at = (totalBrut * AT) / 100;
    const tfp = (totalBrut * TFP) / 100;
    const foporlos = (totalBrut * FOPORLOS) / 100;
    const totalAnnual = cnss + cavis + at + tfp + foporlos;

    return (
      <>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>CP CNSS</TableCell>
                <TableCell>
                  <NumberFormat
                    value={CNSS}
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                    onValueChange={(values) => {
                      const value = Number(values.value);
                      uCNSS(value);
                      updateECCsRate(value / 100);
                    }}
                    suffix="%"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={Math.round(cnss * 1000) / 1000}
                    displayType="text"
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CP CAVIS</TableCell>
                <TableCell>
                  <NumberFormat
                    value={CAVIS}
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                    onValueChange={(values) => {
                      const value = Number(values.value);
                      uCAVIS(value);
                      updateECCavisRate(value / 100);
                    }}
                    suffix="%"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={Math.round(cavis * 1000) / 1000}
                    displayType="text"
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CP AT</TableCell>
                <TableCell>
                  <NumberFormat
                    value={AT}
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                    onValueChange={(values) => {
                      const value = Number(values.value);
                      uAT(Number(value));
                      updateECAtRate(value / 100);
                    }}
                    suffix="%"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={Math.round(at * 1000) / 1000}
                    displayType="text"
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CP TFP</TableCell>
                <TableCell>
                  <NumberFormat
                    value={TFP}
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                    onValueChange={(values) => {
                      const value = Number(values.value);
                      uTFP(Number(value));
                      updateECTfpBase(value / 100);
                    }}
                    suffix="%"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={Math.round(tfp * 1000) / 1000}
                    displayType="text"
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CP FOPORLOS</TableCell>
                <TableCell>
                  <NumberFormat
                    value={FOPORLOS}
                    customInput={TextField}
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                    onValueChange={(values) => {
                      const value = Number(values.value);
                      uFOPORLOS(value);
                      updateECForprolosRate(value / 100);
                    }}
                    suffix="%"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={Math.round(foporlos * 1000) / 1000}
                    displayType="text"
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>Charges patronales annuelles</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    <NumberFormat
                      value={Math.round(totalAnnual * 1000) / 1000}
                      displayType="text"
                      thousandSeparator
                      decimalScale={3}
                      fixedDecimalScale
                    />
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>Coût salarié annuel</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    <NumberFormat
                      value={
                        Math.round((totalAnnual + totalBrut) * 1000) / 1000
                      }
                      displayType="text"
                      thousandSeparator
                      decimalScale={3}
                      fixedDecimalScale
                    />
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>Coût salarié mensuel</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    <NumberFormat
                      value={
                        Math.round(((totalAnnual + totalBrut) / 12) * 1000) /
                        1000
                      }
                      displayType="text"
                      thousandSeparator
                      decimalScale={3}
                      fixedDecimalScale
                    />
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>Coût salarié journalier (22j)</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    <NumberFormat
                      value={
                        Math.round(
                          ((totalAnnual + totalBrut) / 12 / 22) * 1000
                        ) / 1000
                      }
                      displayType="text"
                      thousandSeparator
                      decimalScale={3}
                      fixedDecimalScale
                    />
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  totalBrut: grossTotal(state),
  CNSS: state.cost.CNSS,
  CAVIS: state.cost.CAVIS,
  AT: state.cost.AT,
  TFP: state.cost.TFP,
  FOPORLOS: state.cost.FOPORLOS,
});

const mapDispatch = (dispatch: Dispatch) => ({
  uCNSS: (value: number) => dispatch(updateCNSS(value)),
  uCAVIS: (value: number) => dispatch(updateCAVIS(value)),
  uAT: (value: number) => dispatch(updateAT(value)),
  uTFP: (value: number) => dispatch(updateTFP(value)),
  uFOPORLOS: (value: number) => dispatch(updateFOPORLOS(value)),
  updateECCsRate: (value: number) => dispatch(updateECCsRate(value)),
  updateECCavisRate: (value: number) => dispatch(updateECCavisRate(value)),
  updateECAtRate: (value: number) => dispatch(updateECAtRate(value)),
  updateECTfpBase: (value: number) => dispatch(updateECTfpBase(value)),
  updateECForprolosRate: (value: number) =>
    dispatch(updateECForprolosRate(value)),
});
export default connect(mapState, mapDispatch)(EmployeeCost);
