import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Edit, Close } from "@material-ui/icons";
import { RootState } from "../app/store";
import DependentChildren from "./dependent-children";
import {
  getDependentChildren,
  updateIsLeader,
  updateProfessionalFees,
} from "../app/fiscal-slice";

type Child = {
  id: number;
  is: boolean;
  isDisabled: boolean;
  isScholarshipHolder: boolean;
};
interface IProps {
  store: RootState;
  updateProfessionalFees: (value: boolean) => void;
  updateIsLeader: (value: boolean) => void;
  isProfessionalFees: boolean;
  isLeader: boolean;
}

interface IState {
  isDependentChildOpen: boolean;
}

class CommonDeduction extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isDependentChildOpen: false,
    };
  }

  render() {
    const { isDependentChildOpen } = this.state;
    const {
      store,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      updateIsLeader,
      isLeader,
    } = this.props;
    const dependentChildren = getDependentChildren(store);

    const calc = (child: Child) => {
      if (child.is && !child.isDisabled && !child.isScholarshipHolder) {
        return 100;
      }
      if (child.is && child.isDisabled && !child.isScholarshipHolder) {
        return 2000;
      }
      if (child.is && !child.isDisabled && child.isScholarshipHolder) {
        return 1200;
      }
      if (child.is && child.isDisabled && child.isScholarshipHolder) {
        return 2200;
      }
      return 0;
    };

    return (
      <>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Chef de famille
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  300.000
                </TableCell>
                <TableCell component="th" scope="row">
                  <Checkbox
                    color="primary"
                    value={isLeader}
                    onChange={(arg) => updateIsLeader(!isLeader)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Enfant à charge
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={dependentChildren.reduce(
                      (a: number, b: Child) => a + calc(b),
                      0
                    )}
                    displayType="text"
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Button
                    onClick={() =>
                      this.setState({ isDependentChildOpen: true })
                    }
                  >
                    <Edit />
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          onClose={() => this.setState({ isDependentChildOpen: false })}
          open={isDependentChildOpen}
        >
          <DialogTitle>
            <Grid container justify="space-between">
              <Grid item>Enfant à charge</Grid>
              <Grid item>
                <Button
                  onClick={() => this.setState({ isDependentChildOpen: false })}
                  color="primary"
                >
                  <Close />
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DependentChildren />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  store: state,
  isLeader: state.fiscal.isLeader,
  isProfessionalFees: state.fiscal.isProfessionalFees,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateProfessionalFees: (value: boolean) =>
    dispatch(updateProfessionalFees(value)),
  updateIsLeader: (value: boolean) => dispatch(updateIsLeader(value)),
});

export default connect(mapState, mapDispatch)(CommonDeduction);
