import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PDFState {
  nbrDays: number;
  nbrMonth: number;

  /* ********* PERIOD ************ */
  period: {
    year: number;
    month: string;
  };

  /* ********* EMPLOYEE ************ */
  employee: {
    firstName: string;
    lastName: string;
    internalId: string;
    csId: string;
    cinId: string;
    centerCostId: string;
    jobTitle: string;
    maried: string;
    children: number;
    leaveBalance: number;
    paymentMethod: string;
  };

  /* ********* EMPLOYER ************ */
  employer: {
    name: string;
    address: string;
    addressBis: string;
    csId: number;
  };

  /* ********* GROSS SALARY ************ */
  grossSalary: {
    baseSalary: number;
    workedDays: number;
    transportAllowance: number;
    attendanceBonus: number;
    compensation1: number;
    compensation2: number;
    naturalAdvantage1: number;
    naturalAdvantage2: number;
    brutSalary: number;
  };

  /* ********** PAYROLL DEDUCTIONS *********** */
  pd: {
    csBase: number;
    csRate: number;
    cs: number;
    cavisBase: number;
    cavisRate: number;
    cavis: number;
    irppBase: number;
    irppRate: number;
    irpp: number;
    cssBase: number;
    cssRate: number;
    css: number;
    insuranceGroup: number;
    paydayAdvanceDeduction: number;
    loanHoldback: number;
    totalPayrollDeductions: number;
  };

  /* ********* EMPLOYER CHARGES ************ */
  ec: {
    csBase: number;
    csRate: number;
    cs: number;
    cavisBase: number;
    cavisRate: number;
    cavis: number;
    atBase: number;
    atRate: number;
    at: number;
    tfpBase: number;
    tfpRate: number;
    tfp: number;
    forprolosBase: number;
    forprolosRate: number;
    forprolos: number;
    totalEmployerCharges: number;
  };

  net: number;
}

const initialState: PDFState = {
  nbrDays: 22,
  nbrMonth: 12,

  /* ********* PERIOD ************ */
  period: {
    year: 2021,
    month: "Janvier",
  },

  /* ********* EMPLOYEE ************ */
  employee: {
    firstName: "Flen",
    lastName: "Foulen",
    internalId: "",
    csId: "",
    cinId: "",
    centerCostId: "",
    jobTitle: "",
    maried: "Célibataire",
    children: 0,
    leaveBalance: 0,
    paymentMethod: "Virement",
  },

  /* ********* EMPLOYER ************ */
  employer: {
    name: 'Cabinet Mabrouk Ayari "CMA"',
    address: "43, AV. Habib Bourguiba, le Colisée",
    addressBis: "Bloc B, 3rd Floor, 1001 Tunis, Tunisia",
    csId: 999999999,
  },

  /* ********* GROSS SALARY ************ */
  grossSalary: {
    baseSalary: 0,
    workedDays: 22,
    transportAllowance: 0,
    attendanceBonus: 0,
    compensation1: 0,
    compensation2: 0,
    naturalAdvantage1: 0,
    naturalAdvantage2: 0,
    brutSalary: 0,
  },

  /* ********* PAYROLL DEDUCTIONS ************ */
  pd: {
    csBase: 0,
    csRate: 0.0918,
    cs: 0,
    cavisBase: 0,
    cavisRate: 0.03,
    cavis: 0,
    irppBase: 0,
    irppRate: 0,
    irpp: 0,
    cssBase: 0,
    cssRate: 0.005,
    css: 0,
    insuranceGroup: 0,
    paydayAdvanceDeduction: 0,
    loanHoldback: 0,
    totalPayrollDeductions: 0,
  },

  /* ********* EMPLOYER CHARGES ************ */
  ec: {
    csBase: 0,
    csRate: 0.1657,
    cs: 0,
    cavisBase: 0,
    cavisRate: 0.06,
    cavis: 0,
    atBase: 0,
    atRate: 0.004,
    at: 0,
    tfpBase: 0,
    tfpRate: 0.02,
    tfp: 0,
    forprolosBase: 0,
    forprolosRate: 0.01,
    forprolos: 0,
    totalEmployerCharges: 0,
  },

  net: 0,
};

export const pdfSlice = createSlice({
  name: "pdf",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateNbrDays: (state, action: PayloadAction<number>) => {
      state.nbrDays = action.payload;
    },
    updateNbrMonth: (state, action: PayloadAction<number>) => {
      state.nbrMonth = action.payload;
    },

    /* ********* PERIOD ************ */
    updateYear: (state, action: PayloadAction<number>) => {
      state.period.year = action.payload;
    },
    updateMonth: (state, action: PayloadAction<string>) => {
      state.period.month = action.payload;
    },

    /* ********* EMPLOYEE ************ */
    updateFirstName: (state, action: PayloadAction<string>) => {
      state.employee.firstName = action.payload;
    },
    updateLastName: (state, action: PayloadAction<string>) => {
      state.employee.lastName = action.payload;
    },
    updateInternalId: (state, action: PayloadAction<string>) => {
      state.employee.internalId = action.payload;
    },
    updateCsId: (state, action: PayloadAction<string>) => {
      state.employee.csId = action.payload;
    },
    updateCinId: (state, action: PayloadAction<string>) => {
      state.employee.cinId = action.payload;
    },
    updateCenterCostId: (state, action: PayloadAction<string>) => {
      state.employee.centerCostId = action.payload;
    },
    updateJobTitle: (state, action: PayloadAction<string>) => {
      state.employee.jobTitle = action.payload;
    },
    updateMaried: (state, action: PayloadAction<string>) => {
      state.employee.maried = action.payload;
    },
    updateChildren: (state, action: PayloadAction<number>) => {
      state.employee.children = action.payload;
    },
    updateLeaveBalance: (state, action: PayloadAction<number>) => {
      state.employee.leaveBalance = action.payload;
    },
    updatePaymentMethod: (state, action: PayloadAction<string>) => {
      state.employee.paymentMethod = action.payload;
    },

    /* ********* EMPLOYER ************ */
    updateEmployerName: (state, action: PayloadAction<string>) => {
      state.employer.name = action.payload;
    },
    updateEmployerAddress: (state, action: PayloadAction<string>) => {
      state.employer.address = action.payload;
    },
    updateEmployerAddressBis: (state, action: PayloadAction<string>) => {
      state.employer.addressBis = action.payload;
    },
    updateEmployerCsId: (state, action: PayloadAction<number>) => {
      state.employer.csId = action.payload;
    },

    /* ********* GROSS SALARY ************ */
    updateBaseSalary: (state, action: PayloadAction<number>) => {
      state.grossSalary.baseSalary = action.payload;
    },
    updateWorkedDays: (state, action: PayloadAction<number>) => {
      state.grossSalary.workedDays = action.payload;
    },
    updateTransportAllowance: (state, action: PayloadAction<number>) => {
      state.grossSalary.transportAllowance = action.payload;
    },
    updateAttendanceBonus: (state, action: PayloadAction<number>) => {
      state.grossSalary.attendanceBonus = action.payload;
    },
    updateBrutSalary: (state, action: PayloadAction<number>) => {
      state.grossSalary.brutSalary = action.payload;
    },
    /* ********* PAYROLL DEDUCTIONS ************ */
    updateCsBase: (state, action: PayloadAction<number>) => {
      state.pd.csBase = action.payload;
    },
    updateCsRate: (state, action: PayloadAction<number>) => {
      state.pd.csRate = action.payload;
    },
    updateCs: (state, action: PayloadAction<number>) => {
      state.pd.cs = action.payload;
    },
    updateCavisBase: (state, action: PayloadAction<number>) => {
      state.pd.cavisBase = action.payload;
    },
    updateCavisRate: (state, action: PayloadAction<number>) => {
      state.pd.cavisRate = action.payload;
    },
    updateCavis: (state, action: PayloadAction<number>) => {
      state.pd.cavis = action.payload;
    },
    updateIrppBase: (state, action: PayloadAction<number>) => {
      state.pd.irppBase = action.payload;
    },
    updateIrppRate: (state, action: PayloadAction<number>) => {
      state.pd.irppRate = action.payload;
    },
    updateIrpp: (state, action: PayloadAction<number>) => {
      state.pd.irpp = action.payload;
    },
    updateCssBase: (state, action: PayloadAction<number>) => {
      state.pd.cssBase = action.payload;
    },
    updateCssRate: (state, action: PayloadAction<number>) => {
      state.pd.cssRate = action.payload;
    },
    updateCss: (state, action: PayloadAction<number>) => {
      state.pd.css = action.payload;
    },
    updateInsuranceGroup: (state, action: PayloadAction<number>) => {
      state.pd.insuranceGroup = action.payload;
    },
    updatePaydayAdvanceDeduction: (state, action: PayloadAction<number>) => {
      state.pd.paydayAdvanceDeduction = action.payload;
    },
    updateLoanHoldback: (state, action: PayloadAction<number>) => {
      state.pd.loanHoldback = action.payload;
    },
    updateTotalPayrollDeductions: (state, action: PayloadAction<number>) => {
      state.pd.totalPayrollDeductions = action.payload;
    },

    /* ********* EMPLOYER CHARGES ************ */
    updateECCsBase: (state, action: PayloadAction<number>) => {
      state.ec.csBase = action.payload;
    },
    updateECCsRate: (state, action: PayloadAction<number>) => {
      state.ec.csRate = action.payload;
    },
    updateECCs: (state, action: PayloadAction<number>) => {
      state.ec.cs = action.payload;
    },
    updateECCavisBase: (state, action: PayloadAction<number>) => {
      state.ec.cavisBase = action.payload;
    },
    updateECCavisRate: (state, action: PayloadAction<number>) => {
      state.ec.cavisRate = action.payload;
    },
    updateECCavis: (state, action: PayloadAction<number>) => {
      state.ec.cavis = action.payload;
    },
    updateECAtBase: (state, action: PayloadAction<number>) => {
      state.ec.atBase = action.payload;
    },
    updateECAtRate: (state, action: PayloadAction<number>) => {
      state.ec.atRate = action.payload;
    },
    updateECAt: (state, action: PayloadAction<number>) => {
      state.ec.at = action.payload;
    },
    updateECTfpBase: (state, action: PayloadAction<number>) => {
      state.ec.tfpBase = action.payload;
    },
    updateECTfpRate: (state, action: PayloadAction<number>) => {
      state.ec.tfpRate = action.payload;
    },
    updateECTfp: (state, action: PayloadAction<number>) => {
      state.ec.tfp = action.payload;
    },
    updateECForprolosBase: (state, action: PayloadAction<number>) => {
      state.ec.forprolosBase = action.payload;
    },
    updateECForprolosRate: (state, action: PayloadAction<number>) => {
      state.ec.forprolosRate = action.payload;
    },
    updateECForprolos: (state, action: PayloadAction<number>) => {
      state.ec.forprolos = action.payload;
    },
    updateECTotalEmployerCharges: (state, action: PayloadAction<number>) => {
      state.ec.totalEmployerCharges = action.payload;
    },

    updateNet: (state, action: PayloadAction<number>) => {
      state.net = action.payload;
    },
  },
});

export const {
  updateNbrDays,
  updateNbrMonth,
  /* ********* PERIOD ************ */
  updateYear,
  updateMonth,

  /* ********* EMPLOYEE ************ */
  updateFirstName,
  updateLastName,
  updateInternalId,
  updateCsId,
  updateCinId,
  updateCenterCostId,
  updateJobTitle,
  updateMaried,
  updateChildren,
  updateLeaveBalance,
  updatePaymentMethod,

  /* ********* EMPLOYER ************ */
  updateEmployerName,
  updateEmployerAddress,
  updateEmployerAddressBis,
  updateEmployerCsId,

  /* ********* GROSS SALARY ************ */
  updateBaseSalary,
  updateWorkedDays,
  updateTransportAllowance,
  updateAttendanceBonus,
  updateBrutSalary,

  /* ********* PAYROLL DEDUCTIONS ************ */
  updateCsBase,
  updateCsRate,
  updateCs,
  updateCavisBase,
  updateCavisRate,
  updateCavis,
  updateIrppBase,
  updateIrppRate,
  updateIrpp,
  updateCssBase,
  updateCssRate,
  updateCss,
  updateInsuranceGroup,
  updatePaydayAdvanceDeduction,
  updateLoanHoldback,
  updateTotalPayrollDeductions,

  /* ********* EMPLOYER CHARGES ************ */
  updateECCsBase,
  updateECCsRate,
  updateECCs,
  updateECCavisBase,
  updateECCavisRate,
  updateECCavis,
  updateECAtBase,
  updateECAtRate,
  updateECAt,
  updateECTfpBase,
  updateECTfpRate,
  updateECTfp,
  updateECForprolosBase,
  updateECForprolosRate,
  updateECForprolos,
  updateECTotalEmployerCharges,

  updateNet,
} = pdfSlice.actions;

export type { PDFState };
export default pdfSlice.reducer;
