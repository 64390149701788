import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../app/store";
import MonthPicker from "./month-picker";
import { Dispatch } from "redux";
import { updateYear } from "../app/pdf-slice";

const Period = ({
  period,
  updateYear,
}: {
  period: { month: string; year: number };
  updateYear: (value: number) => void;
}) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Année"
            type="number"
            fullWidth
            value={period.year}
            onChange={(values) => updateYear(Number(values.target.value))}
          />
        </Grid>
        <Grid item xs={12}>
          <MonthPicker />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  period: state.pdf.period,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateYear: (value: number) => dispatch(updateYear(value)),
});

export default connect(mapStateToProps, mapDispatch)(Period);
