import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { updateIsEmployee } from "../app/gross-slice";
import { useAppDispatch } from "../app/hooks";

const IncomeType = () => {
  const dispatch = useAppDispatch();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Type de revenu
            </TableCell>
            <TableCell component="th" scope="row">
              Salarié(e)
              <Switch
                color="primary"
                onChange={(arg) => {
                  dispatch(updateIsEmployee(Boolean(!arg.target.checked)));
                }}
              />
              Indépendant(e)
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IncomeType;
