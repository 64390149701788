/* eslint-disable @typescript-eslint/no-empty-interface */
import { TableCell, TableRow, TextField } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "../app/store";
import {
  getDependentChildren,
  getTaxBenefit,
  updateLimitTaxBenefit,
} from "../app/fiscal-slice";
import {
  clearBase,
  getCavis,
  getProfessionalFees,
  grossTotal,
  grossTotalContribution,
  update,
  updateBase,
} from "../app/gross-slice";
import WorkerBuilder from "../worker/woker-build";
import Worker from "../worker/find-brut-v2.worker";
import CalcEmployeeCost from "../worker/calc-employee-cost.worker";

import {
  updateBrutSalary,
  updateCavis,
  updateCavisBase,
  updateCavisRate,
  updateCs,
  updateCsBase,
  updateCsRate,
  updateCss,
  updateCssBase,
  updateCssRate,
  updateECAt,
  updateECAtBase,
  updateECCavis,
  updateECCavisBase,
  updateECCs,
  updateECCsBase,
  updateECForprolos,
  updateECForprolosBase,
  updateECTfp,
  updateECTfpBase,
  updateECTotalEmployerCharges,
  updateIrpp,
  updateIrppBase,
  updateIrppRate,
  updateNet,
  updateTotalPayrollDeductions,
} from "../app/pdf-slice";

type Child = {
  id: number;
  is: boolean;
  isDisabled: boolean;
  isScholarshipHolder: boolean;
};

type Gross = {
  id: number;
  title: string;
  value: number | string;
  isContribution: boolean;
  isImposition: boolean;
};
interface IProps {
  isLeader: boolean;
  isProfessionalFees: boolean;
  totalGross: number;
  totalContributionBase: number;
  dependentChildren: Child[];
  professionalFees: number;
  cavis: number;
  isSavingAccountAction: boolean;
  isLifeInsurance: boolean;
  taxBenefit: number;
  isEmployee: boolean;
  updateBase: (value: number) => void;
  clearBase: () => void;
  updateCsBase: (value: number) => void;
  updateCsRate: (value: number) => void;
  updateCs: (value: number) => void;
  updateCavisBase: (value: number) => void;
  updateCavisRate: (value: number) => void;
  updateCavis: (value: number) => void;
  updateIrppBase: (value: number) => void;
  updateIrppRate: (value: number) => void;
  updateIrpp: (value: number) => void;
  updateCssBase: (value: number) => void;
  updateCssRate: (value: number) => void;
  updateCss: (value: number) => void;
  updateBrutSalary: (value: number) => void;
  updateTotalPayrollDeductions: (value: number) => void;
  pd: {
    cssRate: number;
    csRate: number;
    cavisRate: number;
    totalPayrollDeductions: number;
  };
  ec: {
    csBase: number;
    csRate: number;
    cs: number;
    cavisBase: number;
    cavisRate: number;
    cavis: number;
    atBase: number;
    atRate: number;
    at: number;
    tfpBase: number;
    tfpRate: number;
    tfp: number;
    forprolosBase: number;
    forprolosRate: number;
    forprolos: number;
    totalEmployerCharges: number;
  };
  grossSalary: {
    brutSalary: number;
  };
  net: number;
  month: number;
  updateECCsBase: (value: number) => void;
  updateECCs: (value: number) => void;
  updateECCavisBase: (value: number) => void;
  updateECCavis: (value: number) => void;
  updateECAtBase: (value: number) => void;
  updateECAt: (value: number) => void;
  updateECTfpBase: (value: number) => void;
  updateECTfp: (value: number) => void;
  updateECForprolosBase: (value: number) => void;
  updateECForprolos: (value: number) => void;
  updateECTotalEmployerCharges: (value: number) => void;
  updateLimitTaxBenefit: (value: number) => void;
  updateNet: (value: number) => void;
}

interface IState {
  loading: boolean;
  focus: boolean;
  mouseOut: boolean;
}

class NetSalary extends React.Component<IProps, IState> {
  private myWorker = new WorkerBuilder(Worker);
  private calEmployeeCost = new WorkerBuilder(CalcEmployeeCost);
  private timer: ReturnType<typeof setTimeout> | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      focus: false,
      mouseOut: true,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { updateBase } = this.props;
    this.myWorker.onmessage = (e) => {
      this.setState({ loading: false });
      const base = Math.round(e.data.totalGross * 1000) / 1000;
      updateBase(base);
      this.update(e.data);
    };
    this.calEmployeeCost.onmessage = (e) => {
      this.updateEmployeeCost(e.data);
    };
  }

  updateEmployeeCost(message: {
    cnss: number;
    cavis: number;
    at: number;
    tfp: number;
    foporlos: number;
    totalAnnual: number;
    base: number;
  }) {
    const {
      updateECCsBase,
      updateECCs,
      updateECCavisBase,
      updateECCavis,
      updateECAtBase,
      updateECAt,
      updateECTfpBase,
      updateECTfp,
      updateECForprolosBase,
      updateECForprolos,
      updateECTotalEmployerCharges,
    } = this.props;
    updateECCsBase(message.base);
    updateECCs(message.cnss);
    updateECCavisBase(message.base);
    updateECCavis(message.cavis);
    updateECTfpBase(message.base);
    updateECAtBase(message.base);
    updateECAt(message.at);
    updateECTfp(message.tfp);
    updateECForprolosBase(message.base);
    updateECForprolos(message.foporlos);
    updateECTotalEmployerCharges(message.totalAnnual);
  }

  update(message: {
    irpp: number;
    irppBase: number;
    cs: number;
    csBase: number;
    csRate: number;
    css: number;
    cssBase: number;
    cssRate: number;
    cavis: number;
    cavisRate: number;
    cavisBase: number;
    totalGross: number;
    totalDeduction: number;
    net: number;
    limitTaxBenefit: number;
  }) {
    const {
      updateCsBase,
      updateCs,
      updateCavisBase,
      updateCavis,
      updateIrppBase,
      updateIrpp,
      updateCssBase,
      updateCss,
      updateBrutSalary,
      updateNet,
      updateTotalPayrollDeductions,
      updateLimitTaxBenefit,
      pd,
      ec,
    } = this.props;

    updateCs(message.cs);
    updateCsBase(message.csBase);
    updateCss(message.css);
    updateCssBase(message.cssBase);
    updateIrppBase(message.irppBase);
    updateIrpp(message.irpp);
    updateCavis(message.cavis);
    updateCavisBase(message.cavisBase);
    updateBrutSalary(message.totalGross);
    updateNet(message.net);
    updateTotalPayrollDeductions(message.totalDeduction);
    updateLimitTaxBenefit(message.limitTaxBenefit);

    this.calEmployeeCost.postMessage({
      totalBrut: message.totalGross,
      cnssRate: ec.csRate,
      cavisRate: ec.cavisRate,
      atRate: ec.atRate,
      tfpRate: ec.tfpRate,
      forprolosRate: ec.forprolosRate,
    });
  }

  render() {
    const {
      dependentChildren,
      isLeader,
      isProfessionalFees,
      taxBenefit,
      isEmployee,
      net,
      pd,
      month,
    } = this.props;
    const { csRate, cavisRate, cssRate } = pd;

    const { loading, focus, mouseOut } = this.state;

    const calc = (child: Child) => {
      if (child.is && !child.isDisabled && !child.isScholarshipHolder) {
        return 100;
      }
      if (child.is && child.isDisabled && !child.isScholarshipHolder) {
        return 2000;
      }
      if (child.is && !child.isDisabled && child.isScholarshipHolder) {
        return 1200;
      }
      if (child.is && child.isDisabled && child.isScholarshipHolder) {
        return 2200;
      }
      return 0;
    };

    const dependentChildrenDeduction = dependentChildren.reduce(
      (a, b) => a + calc(b),
      0
    );

    const findBrutA = async (targetNet: number) => {
      // eslint-disable-next-line new-cap
      this.myWorker.postMessage({
        targetNet,
        isProfessionalFees,
        isLeader,
        isEmployee,
        dependentChildrenDeduction,
        taxBenefit,
        csRate,
        cavisRate,
        cssRate,
        month,
      });
    };

    return (
      <TableRow>
        <TableCell component="th" scope="row">
          <strong>{isEmployee ? "Salaire net" : "Revenu net"}</strong>
        </TableCell>
        <TableCell />
        <TableCell
          component="th"
          scope="row"
          align="right"
          onMouseEnter={() => {
            this.setState({ mouseOut: false });
          }}
          onMouseLeave={() => {
            if (!focus) {
              this.setState({ mouseOut: true });
            } else {
              this.setState({ mouseOut: true });
            }
          }}
        >
          <strong>
            {!mouseOut || focus ? (
              <TextField
                type="number"
                defaultValue={Math.round((net / 12) * 1000) / 1000}
                disabled={loading}
                onChange={(values) => {
                  if (!loading) {
                    if (this.timer) {
                      clearTimeout(this.timer);
                    }

                    this.timer = setTimeout(() => {
                      findBrutA(
                        // eslint-disable-next-line radix
                        parseInt(values.target.value.replaceAll(",", "")) * 12
                      );
                    }, 1000);
                  }
                }}
                onFocus={() => {
                  this.setState({ focus: true });
                }}
                onBlur={() => {
                  this.setState({ focus: false });
                }}
              />
            ) : (
              <NumberFormat
                value={Math.round((net / 12) * 1000) / 1000}
                thousandSeparator
                decimalScale={3}
                fixedDecimalScale
                customInput={TextField}
                disabled={loading}
              />
            )}
          </strong>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          onMouseEnter={() => {
            this.setState({ mouseOut: false });
          }}
          onMouseLeave={() => {
            if (!focus) {
              this.setState({ mouseOut: true });
            } else {
              this.setState({ mouseOut: true });
            }
          }}
        >
          <strong>
            {!mouseOut || focus ? (
              <TextField
                type="number"
                defaultValue={Math.round(net * 1000) / 1000}
                disabled={loading}
                onChange={(values) => {
                  if (!loading) {
                    if (this.timer) {
                      clearTimeout(this.timer);
                    }
                    this.timer = setTimeout(() => {
                      findBrutA(
                        // eslint-disable-next-line radix
                        parseInt(values.target.value.replaceAll(",", ""))
                      );
                    }, 1000);
                  }
                }}
                onFocus={() => {
                  this.setState({ focus: true });
                }}
                onBlur={() => {
                  this.setState({ focus: false });
                }}
              />
            ) : (
              <NumberFormat
                value={Math.round(net * 1000) / 1000}
                thousandSeparator
                decimalScale={3}
                fixedDecimalScale
                customInput={TextField}
                disabled={loading}
              />
            )}
          </strong>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    );
  }
}
const mapState = (state: RootState) => ({
  isLeader: state.fiscal.isLeader,
  isProfessionalFees: state.fiscal.isProfessionalFees,
  totalGross: grossTotal(state),
  totalContributionBase: grossTotalContribution(state),
  dependentChildren: getDependentChildren(state),
  professionalFees: getProfessionalFees(state),
  cavis: getCavis(state),
  isSavingAccountAction: state.fiscal.isSavingAccountAction,
  isLifeInsurance: state.fiscal.isLifeInsurance,
  taxBenefit: getTaxBenefit(state),
  isEmployee: state.gross.isEmployee,
  pd: state.pdf.pd,
  grossSalary: state.pdf.grossSalary,
  net: state.pdf.net,
  month: state.pdf.nbrMonth,
  ec: state.pdf.ec,
});

const mapDispatch = (dispatch: Dispatch) => ({
  update: (value: Gross) => dispatch(update(value)),
  updateBase: (value: number) => dispatch(updateBase(value)),
  clearBase: () => dispatch(clearBase()),
  updateCsBase: (value: number) => dispatch(updateCsBase(value)),
  updateCsRate: (value: number) => dispatch(updateCsRate(value)),
  updateCs: (value: number) => dispatch(updateCs(value)),
  updateCavisBase: (value: number) => dispatch(updateCavisBase(value)),
  updateCavisRate: (value: number) => dispatch(updateCavisRate(value)),
  updateCavis: (value: number) => dispatch(updateCavis(value)),
  updateIrppBase: (value: number) => dispatch(updateIrppBase(value)),
  updateIrppRate: (value: number) => dispatch(updateIrppRate(value)),
  updateIrpp: (value: number) => dispatch(updateIrpp(value)),
  updateCssBase: (value: number) => dispatch(updateCssBase(value)),
  updateCssRate: (value: number) => dispatch(updateCssRate(value)),
  updateCss: (value: number) => dispatch(updateCss(value)),
  updateBrutSalary: (value: number) => dispatch(updateBrutSalary(value)),
  updateLimitTaxBenefit: (value: number) =>
    dispatch(updateLimitTaxBenefit(value)),
  updateECCsBase: (value: number) => dispatch(updateECCsBase(value)),
  updateECCs: (value: number) => dispatch(updateECCs(value)),
  updateECCavisBase: (value: number) => dispatch(updateECCavisBase(value)),
  updateECCavis: (value: number) => dispatch(updateECCavis(value)),
  updateECAtBase: (value: number) => dispatch(updateECAtBase(value)),
  updateECAt: (value: number) => dispatch(updateECAt(value)),
  updateECTfpBase: (value: number) => dispatch(updateECTfpBase(value)),
  updateECTfp: (value: number) => dispatch(updateECTfp(value)),
  updateECForprolosBase: (value: number) =>
    dispatch(updateECForprolosBase(value)),
  updateECForprolos: (value: number) => dispatch(updateECForprolos(value)),
  updateECTotalEmployerCharges: (value: number) =>
    dispatch(updateECTotalEmployerCharges(value)),
  updateNet: (value: number) => dispatch(updateNet(value)),
  updateTotalPayrollDeductions: (value: number) =>
    dispatch(updateTotalPayrollDeductions(value)),
});

export default connect(mapState, mapDispatch)(NetSalary);
