import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import grossReducer from "./gross-slice";
import fiscalReducer from "./fiscal-slice";
import costReducer from "./cost-slice";
import pdfReducer from "./pdf-slice";

export const store = configureStore({
  reducer: {
    gross: grossReducer,
    fiscal: fiscalReducer,
    cost: costReducer,
    pdf: pdfReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
