// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (message) => {
    const {
      totalGross,
      totalContributionBase,
      isProfessionalFees,
      isLeader,
      isEmployee,
      dependentChildrenDeduction,
      taxBenefit,
      csRate,
      cavisRate,
      cssRate,
      month,
    } = message.data;
    let irpp = 0;
    let irppBase = 0;
    let cs = 0;
    let csBase = 0;
    let net = 0;
    let totalDeduction = 0;
    let PF = 0;
    let cavisBase = 0;
    let cavis = 0;
    let taxBenefitWithLimit = 0;
    let css = 0;
    let cssBase = 0;

    const totalContribution = totalContributionBase * csRate;

    csBase = totalGross;
    cs = csBase * csRate;

    totalDeduction = 0;

    const calcIrpp = (irppBase) => {
      if (irppBase <= 5000) {
        return 0;
      } else if (irppBase > 5000 && irppBase <= 20000) {
        return (irppBase - 5000) * 0.26;
      } else if (irppBase > 20000 && irppBase <= 30000) {
        return (irppBase - 20000) * 0.28 + 3900;
      } else if (irppBase > 30000 && irppBase <= 50000) {
        return (irppBase - 30000) * 0.32 + 6700;
      } else if (irppBase > 50000) {
        return (irppBase - 50000) * 0.35 + 13100;
      }
    };

    const calcLimitTaxBenefit = (irppv) => {
      let lim = 0;
      while (
        irppv * 0.45 <
        calcIrpp(
          Math.round(
            totalGross - totalContribution - totalDeduction - cavis - lim
          )
        )
      ) {
        lim += 1;
      }
      return lim;
    };

    if (isProfessionalFees) {
      const baseFiscalAdvantages = totalGross - cs;
      PF = baseFiscalAdvantages * 0.1;
      if (PF <= 2000) {
        totalDeduction = PF;
      } else {
        totalDeduction = 2000;
      }
    }
    if (isLeader) {
      totalDeduction += 300;
    }

    totalDeduction += dependentChildrenDeduction;

    const SMIG6 = 2476.8;

    cavisBase = totalGross - SMIG6 * month;

    if (!isEmployee || cavisBase < 0) {
      cavisBase = 0;
    } else {
      cavis = cavisBase * cavisRate;
    }

    let base = Math.round(
      isEmployee
        ? totalGross - cs - totalDeduction - cavis
        : totalGross - totalDeduction
    );

    if (base < 0) {
      base = 0;
    }

    const irppCalculated = calcIrpp(base);

    const limitTaxBenefit = calcLimitTaxBenefit(irppCalculated);

    if (limitTaxBenefit < taxBenefit) {
      taxBenefitWithLimit = limitTaxBenefit;
    } else {
      taxBenefitWithLimit = taxBenefit;
    }

    let baseWithTaxBenefitWithLimit = Math.round(
      totalGross -
        totalContribution -
        totalDeduction -
        cavis -
        taxBenefitWithLimit
    );

    if (baseWithTaxBenefitWithLimit < 0) {
      baseWithTaxBenefitWithLimit = 0;
    }

    const irppWithTaxBenefitWithLimitCalculated = calcIrpp(
      baseWithTaxBenefitWithLimit
    );

    if (irppCalculated * 0.45 > irppWithTaxBenefitWithLimitCalculated) {
      irpp = irppCalculated;
      irppBase = base;
      cssBase = base;
    } else {
      irpp = irppWithTaxBenefitWithLimitCalculated;
      irppBase = baseWithTaxBenefitWithLimit;
      cssBase = baseWithTaxBenefitWithLimit;
    }

    css = cssBase * cssRate;

    net = isEmployee
      ? totalGross - irpp - cs - cavis - css
      : totalGross - irpp - css;

    totalDeduction = isEmployee ? irpp + cs + cavis + css : irpp + css;

    const result = {
      totalGross,
      totalDeduction,
      css,
      cssBase,
      cssRate,
      irpp,
      irppBase,
      cs,
      csBase,
      csRate,
      cavis,
      cavisBase,
      cavisRate,
      net,
      limitTaxBenefit,
    };
    postMessage(result);
  };
};
