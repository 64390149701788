import React from "react";
import { Checkbox, TableCell, TableRow, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import {
  updateIsSavingAccountAction,
  updateSavingAccountAction,
} from "../app/fiscal-slice";

import { useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import { connect } from "react-redux";

const SavingAccountAction = ({
  savingAccountAction,
}: {
  savingAccountAction: number;
}) => {
  const dispatch = useAppDispatch();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        Compte epargne en action (CEA)
      </TableCell>
      <TableCell scope="row" align="right">
        <NumberFormat
          customInput={TextField}
          thousandSeparator
          decimalScale={3}
          fixedDecimalScale
          value={savingAccountAction}
          onValueChange={(values) => {
            const value = Number(values.value);
            if (value <= 100000) {
              dispatch(updateSavingAccountAction(value));
            } else {
              dispatch(updateSavingAccountAction(100000));
            }
          }}
        />
      </TableCell>
      <TableCell scope="row">
        <Checkbox
          color="primary"
          onChange={(arg) => {
            dispatch(updateIsSavingAccountAction(Boolean(arg.target.checked)));
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state: RootState) => ({
  savingAccountAction: state.fiscal.savingAccountAction,
});

export default connect(mapStateToProps)(SavingAccountAction);
