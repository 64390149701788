import React, { useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Checkbox,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import NumberFormat from "react-number-format";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  add,
  getCavis,
  getGrossSalary,
  getIsEmployee,
  getProfessionalFees,
  grossTotal,
  grossTotalContribution,
  remove,
  update,
  updateBase,
} from "../app/gross-slice";
import Contributions from "./contributions";
import Impositions from "./impositions";
import NetSalary from "./net-salary";
import SpecialSolidarityContribution from "./special-solidarity-contribution";
import Cavis from "./cavis";
import WorkerBuilder from "../worker/woker-build";
import CalcNet from "../worker/calc-net.worker";
import CalcEmployeeCost from "../worker/calc-employee-cost.worker";
import { Dispatch } from "redux";
import { RootState } from "../app/store";
import {
  getDependentChildren,
  getTaxBenefit,
  updateLimitTaxBenefit,
} from "../app/fiscal-slice";
import {
  updateBaseSalary,
  updateBrutSalary,
  updateCavis,
  updateCavisBase,
  updateCavisRate,
  updateCs,
  updateCsBase,
  updateCsRate,
  updateCss,
  updateCssBase,
  updateCssRate,
  updateECAt,
  updateECAtBase,
  updateECCavis,
  updateECCavisBase,
  updateECCs,
  updateECCsBase,
  updateECForprolos,
  updateECForprolosBase,
  updateECTfp,
  updateECTfpBase,
  updateECTotalEmployerCharges,
  updateIrpp,
  updateIrppBase,
  updateIrppRate,
  updateNet,
  updateTotalPayrollDeductions,
} from "../app/pdf-slice";

type Gross = {
  id: number;
  title: string;
  value: number | string;
  isContribution: boolean;
  isImposition: boolean;
};

type Child = {
  id: number;
  is: boolean;
  isDisabled: boolean;
  isScholarshipHolder: boolean;
};

interface IProps {
  isLeader: boolean;
  isProfessionalFees: boolean;
  totalGross: number;
  totalContributionBase: number;
  dependentChildren: Child[];
  professionalFees: number;
  cavis: number;
  isSavingAccountAction: boolean;
  isLifeInsurance: boolean;
  taxBenefit: number;
  isEmployee: boolean;
  add: () => void;
  remove: (value: number) => void;
  update: (value: Gross) => void;
  updateBase: (value: number) => void;
  updateCsBase: (value: number) => void;
  updateCsRate: (value: number) => void;
  updateCs: (value: number) => void;
  updateCavisBase: (value: number) => void;
  updateCavisRate: (value: number) => void;
  updateCavis: (value: number) => void;
  updateIrppBase: (value: number) => void;
  updateIrppRate: (value: number) => void;
  updateIrpp: (value: number) => void;
  updateCssBase: (value: number) => void;
  updateCssRate: (value: number) => void;
  updateCss: (value: number) => void;
  updateBrutSalary: (value: number) => void;
  updateNet: (value: number) => void;
  updateTotalPayrollDeductions: (value: number) => void;
  updateBaseSalary: (value: number) => void;
  pd: { cssRate: number; csRate: number; cavisRate: number };
  ec: {
    csBase: number;
    csRate: number;
    cs: number;
    cavisBase: number;
    cavisRate: number;
    cavis: number;
    atBase: number;
    atRate: number;
    at: number;
    tfpBase: number;
    tfpRate: number;
    tfp: number;
    forprolosBase: number;
    forprolosRate: number;
    forprolos: number;
    totalEmployerCharges: number;
  };
  grossSalary: Gross[];
  updateECCsBase: (value: number) => void;
  updateECCs: (value: number) => void;
  updateECCavisBase: (value: number) => void;
  updateECCavis: (value: number) => void;
  updateECAtBase: (value: number) => void;
  updateECAt: (value: number) => void;
  updateECTfpBase: (value: number) => void;
  updateECTfp: (value: number) => void;
  updateECForprolosBase: (value: number) => void;
  updateECForprolos: (value: number) => void;
  updateECTotalEmployerCharges: (value: number) => void;
  updateLimitTaxBenefit: (value: number) => void;
  month: number;
}

interface IState {
  loading: boolean;
  focus: boolean;
  mouseOut: boolean;
}
class GrossSalary extends React.Component<IProps, IState> {
  private calNet = new WorkerBuilder(CalcNet);

  private calEmployeeCost = new WorkerBuilder(CalcEmployeeCost);

  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      focus: false,
      mouseOut: true,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { updateBase } = this.props;

    this.calNet.onmessage = (e) => {
      this.update(e.data);
    };

    this.calEmployeeCost.onmessage = (e) => {
      this.updateEmployeeCost(e.data);
    };
  }

  updateEmployeeCost(message: {
    cnss: number;
    cavis: number;
    at: number;
    tfp: number;
    foporlos: number;
    totalAnnual: number;
    base: number;
  }) {
    const {
      updateECCsBase,
      updateECCs,
      updateECCavisBase,
      updateECCavis,
      updateECAtBase,
      updateECAt,
      updateECTfpBase,
      updateECTfp,
      updateECForprolosBase,
      updateECForprolos,
      updateECTotalEmployerCharges,
    } = this.props;
    updateECCsBase(message.base);
    updateECCs(message.cnss);
    updateECCavisBase(message.base);
    updateECCavis(message.cavis);
    updateECTfpBase(message.base);
    updateECAtBase(message.base);
    updateECAt(message.at);
    updateECTfp(message.tfp);
    updateECForprolosBase(message.base);
    updateECForprolos(message.foporlos);
    updateECTotalEmployerCharges(message.totalAnnual);
  }

  update(message: {
    irpp: number;
    irppBase: number;
    cs: number;
    csBase: number;
    csRate: number;
    css: number;
    cssBase: number;
    cssRate: number;
    cavis: number;
    cavisRate: number;
    cavisBase: number;
    totalGross: number;
    totalDeduction: number;
    net: number;
    limitTaxBenefit: number;
  }) {
    const {
      updateCsBase,
      updateCs,
      updateCavisBase,
      updateCavis,
      updateIrppBase,
      updateIrpp,
      updateCssBase,
      updateCss,
      updateBrutSalary,
      updateNet,
      updateTotalPayrollDeductions,
      updateLimitTaxBenefit,
      ec,
    } = this.props;

    updateCs(message.cs);
    updateCsBase(message.csBase);
    updateCss(message.css);
    updateCssBase(message.cssBase);
    updateIrppBase(message.irppBase);
    updateIrpp(message.irpp);
    updateCavis(message.cavis);
    updateCavisBase(message.cavisBase);
    updateBrutSalary(message.totalGross);
    updateNet(message.net);
    updateTotalPayrollDeductions(message.totalDeduction);
    updateLimitTaxBenefit(message.limitTaxBenefit);

    this.calEmployeeCost.postMessage({
      totalBrut: message.totalGross,
      cnssRate: ec.csRate,
      cavisRate: ec.cavisRate,
      atRate: ec.atRate,
      tfpRate: ec.tfpRate,
      forprolosRate: ec.forprolosRate,
    });
  }

  render() {
    const { mouseOut, focus } = this.state;
    const {
      totalGross,
      grossSalary,
      totalContributionBase,
      isProfessionalFees,
      isLeader,
      dependentChildren,
      taxBenefit,
      isEmployee,
      month,
      add,
      remove,
      update,
      updateBaseSalary,
      pd,
    } = this.props;

    const calc = (child: Child) => {
      if (child.is && !child.isDisabled && !child.isScholarshipHolder) {
        return 100;
      }
      if (child.is && child.isDisabled && !child.isScholarshipHolder) {
        return 2000;
      }
      if (child.is && !child.isDisabled && child.isScholarshipHolder) {
        return 1200;
      }
      if (child.is && child.isDisabled && child.isScholarshipHolder) {
        return 2200;
      }
      return 0;
    };

    const dependentChildrenDeduction = dependentChildren.reduce(
      (a, b) => a + calc(b),
      0
    );

    this.calNet.postMessage({
      totalGross,
      totalContributionBase,
      isProfessionalFees,
      isLeader,
      isEmployee,
      dependentChildrenDeduction,
      taxBenefit,
      cssRate: pd.cssRate,
      csRate: pd.csRate,
      cavisRate: pd.cavisRate,
      month,
    });

    return (
      <>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Designation</strong>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <strong>Mensuel</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Annuel</strong>
                </TableCell>
                {isEmployee ? (
                  <TableCell>
                    <strong>Cotisable</strong>
                  </TableCell>
                ) : (
                  <></>
                )}
                <TableCell>
                  <strong>Imposable</strong>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {grossSalary.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id === 1 ? row.title : <TextField />}
                  </TableCell>
                  <TableCell />
                  <TableCell
                    component="th"
                    scope="row"
                    align="right"
                    onMouseEnter={() => this.setState({ mouseOut: false })}
                    onMouseLeave={() => {
                      this.setState({ mouseOut: true });
                    }}
                  >
                    {!mouseOut || focus ? (
                      <TextField
                        type="number"
                        defaultValue={
                          Math.round(
                            ((typeof row.value === "number" ? row.value : 0) /
                              12) *
                              1000
                          ) / 1000
                        }
                        onChange={(values) => {
                          const value =
                            Number(values.target.value.replaceAll(",", "")) *
                            12;
                          update({
                            ...row,
                            value,
                          });
                          if (row.id === 1) {
                            updateBaseSalary(value);
                          }
                        }}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                      />
                    ) : (
                      <NumberFormat
                        value={
                          Math.round(
                            ((typeof row.value === "number" ? row.value : 0) /
                              12) *
                              1000
                          ) / 1000
                        }
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        customInput={TextField}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                    onMouseEnter={() => this.setState({ mouseOut: false })}
                    onMouseLeave={() => this.setState({ mouseOut: true })}
                  >
                    {!mouseOut || focus ? (
                      <TextField
                        type="number"
                        defaultValue={
                          Math.round(
                            (typeof row.value === "number" ? row.value : 0) *
                              1000
                          ) / 1000
                        }
                        onChange={(values) => {
                          const value = Number(
                            values.target.value.replaceAll(",", "")
                          );
                          update({
                            ...row,
                            value,
                          });
                          if (row.id === 1) {
                            updateBaseSalary(value);
                          }
                        }}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                      />
                    ) : (
                      <NumberFormat
                        value={
                          Math.round(
                            (typeof row.value === "number" ? row.value : 0) *
                              1000
                          ) / 1000
                        }
                        customInput={TextField}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        onChange={(values) => {
                          update({
                            ...row,
                            value: Number(
                              values.target.value.replaceAll(",", "")
                            ),
                          });
                        }}
                      />
                    )}
                  </TableCell>
                  {isEmployee ? (
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={row.isContribution}
                        onChange={(arg) => {
                          update({
                            ...row,
                            isContribution: !row.isContribution,
                          });
                        }}
                      />
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={row.isImposition}
                      onChange={(arg) => {
                        update({ ...row, isImposition: !row.isImposition });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {row.id !== 1 ? (
                      <Button
                        onClick={() => {
                          remove(row.id);
                        }}
                      >
                        <RemoveIcon />
                      </Button>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell component="th" scope="row">
                  <strong>
                    {isEmployee ? "Total brut" : "Total revenu imposable"}
                  </strong>
                </TableCell>
                <TableCell />
                <TableCell component="th" scope="row" align="right">
                  <strong>
                    <NumberFormat
                      value={Math.round((totalGross / 12) * 1000) / 1000}
                      displayType="text"
                      thousandSeparator
                      decimalScale={3}
                      fixedDecimalScale
                    />
                  </strong>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <strong>
                    <NumberFormat
                      value={Math.round(totalGross * 1000) / 1000}
                      displayType="text"
                      thousandSeparator
                      decimalScale={3}
                      fixedDecimalScale
                    />
                  </strong>
                </TableCell>
                <TableCell />
                <TableCell>
                  <Button
                    onClick={() => {
                      add();
                    }}
                  >
                    <AddIcon />
                  </Button>
                </TableCell>
              </TableRow>
              {isEmployee ? <Contributions /> : <></>}
              {isEmployee ? <Cavis /> : <></>}
              <SpecialSolidarityContribution />
              <Impositions />
              <NetSalary />
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  isLeader: state.fiscal.isLeader,
  isProfessionalFees: state.fiscal.isProfessionalFees,
  totalGross: grossTotal(state),
  totalContributionBase: grossTotalContribution(state),
  dependentChildren: getDependentChildren(state),
  professionalFees: getProfessionalFees(state),
  cavis: getCavis(state),
  isSavingAccountAction: state.fiscal.isSavingAccountAction,
  isLifeInsurance: state.fiscal.isLifeInsurance,
  taxBenefit: getTaxBenefit(state),
  isEmployee: state.gross.isEmployee,
  pd: state.pdf.pd,
  grossSalary: getGrossSalary(state),
  ec: state.pdf.ec,
  month: state.pdf.nbrMonth,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateBase: (value: number) => dispatch(updateBase(value)),
  updateCsBase: (value: number) => dispatch(updateCsBase(value)),
  updateCsRate: (value: number) => dispatch(updateCsRate(value)),
  updateCs: (value: number) => dispatch(updateCs(value)),
  updateCavisBase: (value: number) => dispatch(updateCavisBase(value)),
  updateCavisRate: (value: number) => dispatch(updateCavisRate(value)),
  updateCavis: (value: number) => dispatch(updateCavis(value)),
  updateIrppBase: (value: number) => dispatch(updateIrppBase(value)),
  updateIrppRate: (value: number) => dispatch(updateIrppRate(value)),
  updateIrpp: (value: number) => dispatch(updateIrpp(value)),
  updateCssBase: (value: number) => dispatch(updateCssBase(value)),
  updateCssRate: (value: number) => dispatch(updateCssRate(value)),
  updateCss: (value: number) => dispatch(updateCss(value)),
  updateBaseSalary: (value: number) => dispatch(updateBaseSalary(value)),
  updateBrutSalary: (value: number) => dispatch(updateBrutSalary(value)),
  updateTotalPayrollDeductions: (value: number) =>
    dispatch(updateTotalPayrollDeductions(value)),
  update: (value: Gross) => dispatch(update(value)),
  updateNet: (value: number) => dispatch(updateNet(value)),
  add: () => dispatch(add()),
  remove: (value: number) => dispatch(remove(value)),
  updateECCsBase: (value: number) => dispatch(updateECCsBase(value)),
  updateECCs: (value: number) => dispatch(updateECCs(value)),
  updateECCavisBase: (value: number) => dispatch(updateECCavisBase(value)),
  updateECCavis: (value: number) => dispatch(updateECCavis(value)),
  updateECAtBase: (value: number) => dispatch(updateECAtBase(value)),
  updateECAt: (value: number) => dispatch(updateECAt(value)),
  updateECTfpBase: (value: number) => dispatch(updateECTfpBase(value)),
  updateECTfp: (value: number) => dispatch(updateECTfp(value)),
  updateECForprolosBase: (value: number) =>
    dispatch(updateECForprolosBase(value)),
  updateECForprolos: (value: number) => dispatch(updateECForprolos(value)),
  updateECTotalEmployerCharges: (value: number) =>
    dispatch(updateECTotalEmployerCharges(value)),
  updateLimitTaxBenefit: (value: number) =>
    dispatch(updateLimitTaxBenefit(value)),
});

export default connect(mapState, mapDispatch)(GrossSalary);
