import { Grid, TextField } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  updateEmployerAddress,
  updateEmployerAddressBis,
  updateEmployerCsId,
  updateEmployerName,
} from "../app/pdf-slice";
import { RootState } from "../app/store";

const EmployerForm = ({
  employer,
  updateEmployerName,
  updateEmployerAddress,
  updateEmployerAddressBis,
  updateEmployerCsId,
}: {
  employer: { name: string; address: string; addressBis: string; csId: number };
  updateEmployerName: (value: string) => void;
  updateEmployerAddress: (value: string) => void;
  updateEmployerAddressBis: (value: string) => void;
  updateEmployerCsId: (value: number) => void;
}) => {
  return (
    <Grid container spacing={1} direction="column">
      <Grid item xs={12}>
        <TextField
          label="Nom entreprise"
          type="string"
          defaultValue={employer.name}
          fullWidth
          onChange={(values) => updateEmployerName(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Adresse"
          type="string"
          defaultValue={employer.address}
          fullWidth
          onChange={(values) => updateEmployerAddress(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Adresse bis"
          type="string"
          defaultValue={employer.addressBis}
          fullWidth
          onChange={(values) => updateEmployerAddressBis(values.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="N° de sécurité sociale de l'employeur"
          type="number"
          defaultValue={employer.csId}
          fullWidth
          onChange={(values) => updateEmployerCsId(Number(values.target.value))}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  employer: state.pdf.employer,
});

const mapDispatch = (dispatch: Dispatch) => ({
  updateEmployerName: (value: string) => dispatch(updateEmployerName(value)),
  updateEmployerAddress: (value: string) =>
    dispatch(updateEmployerAddress(value)),
  updateEmployerAddressBis: (value: string) =>
    dispatch(updateEmployerAddressBis(value)),
  updateEmployerCsId: (value: number) => dispatch(updateEmployerCsId(value)),
});

export default connect(mapStateToProps, mapDispatch)(EmployerForm);
