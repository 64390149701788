import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { connect } from "react-redux";
import roboto from "../fonts/Roboto-Regular.ttf";
import robotoBold from "../fonts/Roboto-Bold.ttf";
import { RootState } from "../app/store";
import { PDFState } from "../app/pdf-slice";

Font.register({ family: "Roboto", src: roboto, format: "truetype" });
Font.register({ family: "Roboto-Bold", src: robotoBold, format: "truetype" });

const styles = StyleSheet.create({
  spacer: {
    marginBottom: "25px",
  },
  page: {
    backgroundColor: "#fff",
    height: "842px",
    width: "595px",
  },
  section: { display: "flex", flexDirection: "row" },
  employer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    marginLeft: "30px",
  },
  employerNameText: {
    fontFamily: "Roboto-Bold",
    fontSize: "14px",
    marginBottom: "5px",
  },
  employerAddressText: {
    fontFamily: "Roboto",
    fontSize: "11px",
  },
  employerSocialIdText: {
    fontFamily: "Roboto-Bold",
    fontSize: "11px",
    marginTop: "5px",
  },
  employerSocialIdValueText: {
    fontFamily: "Roboto-Bold",
    fontSize: "11px",
  },
  right: { marginRight: "30px" },
  left: { flexGrow: 1, marginLeft: "30px", marginRight: "30px" },
  leftHeader: {
    fontFamily: "Roboto-Bold",
    fontSize: "12px",
    textDecoration: "underline",
    marginBottom: "2px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  header: {
    textAlign: "center",
    margin: "20px",
    fontFamily: "Roboto-Bold",
    fontSize: "20px",
  },
  item: { marginBottom: "10px", padding: "5px" },
  itemHeader: { fontFamily: "Roboto-Bold", fontSize: "12px" },
  itemContent: { fontFamily: "Roboto", fontSize: "12px" },
  itemFullname: {
    marginBottom: "10px",
    backgroundColor: "#fafafa",
    border: "1px solid black",
    padding: "5px",
    width: "auto",
  },

  table: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  column: { flexGrow: 1 },
  tableItem: { marginBottom: "2px" },
  totalGrey: {
    border: "1px solid black",
    padding: "5px",
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fafafa",
  },
  total: {
    borderTop: "2px solid black",
    paddingTop: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
  },
  totalItem: { flexGrow: 1 },
  totalItemValue: {},
  tableItemHeaderText: {
    fontFamily: "Roboto-Bold",
    fontSize: "12px",
  },
  tableItemText: { fontFamily: "Roboto", fontSize: "12px" },
  tableItemRight: {
    alignSelf: "flex-end",
    marginBottom: "2px",
    minHeight: "5px",
  },
  tableItemCenter: {
    alignSelf: "center",
    marginBottom: "2px",
    minHeight: "5px",
  },
  tableItemHeaderValue: {
    alignSelf: "flex-end",
    marginBottom: "2px",
    minHeight: "5px",
  },
  tableItemHeaderValueText: {
    fontFamily: "Roboto-Bold",
    fontSize: "12px",
  },
});

const PDF = ({
  employee,
  ec,
  pd,
  grossSalary,
  net,
  employer,
  period,
  nbrDays,
  nbrMonth,
}: PDFState) => {
  return (
    <>
      <PDFViewer style={{ height: "842px", width: "100%" }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.employer}>
              <Text style={styles.employerNameText}>{employer.name}</Text>
              <Text style={styles.employerAddressText}>{employer.address}</Text>
              <Text style={styles.employerAddressText}>
                {employer.addressBis}
              </Text>
              <Text style={styles.employerSocialIdText}>
                N° de sécurité sociale de l'employeur
              </Text>
              <Text style={styles.employerSocialIdValueText}>
                {employer.csId}
              </Text>
            </View>
            <View style={styles.header}>
              <Text>
                BULLETIN DE PAIE DU MOIS DE {period.month.toUpperCase()}{" "}
                {period.year}
              </Text>
            </View>
            <View style={styles.section}>
              <View style={styles.left}>
                <Text style={styles.leftHeader}>SALAIRE BRUT</Text>
                <View style={styles.table}>
                  <View style={styles.column}>
                    <View style={styles.tableItem}>
                      <Text style={styles.tableItemHeaderText}>
                        Désignation
                      </Text>
                    </View>
                    {grossSalary.baseSalary > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>
                          Salaire de base
                        </Text>
                      </View>
                    ) : null}
                    {grossSalary.transportAllowance > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>
                          Indemnité de transport
                        </Text>
                      </View>
                    ) : null}

                    {grossSalary.attendanceBonus > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>
                          Prime de présence
                        </Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemCenter}>
                      <Text style={styles.tableItemHeaderText}>
                        Nbr de jours
                      </Text>
                    </View>
                    {grossSalary.baseSalary > 0 ? (
                      <View style={styles.tableItemCenter}>
                        <Text style={styles.tableItemText}>
                          {grossSalary.workedDays}
                        </Text>
                      </View>
                    ) : null}

                    <View />
                    <View />
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemHeaderValue}>
                      <Text style={styles.tableItemHeaderValueText}>
                        Montant
                      </Text>
                    </View>
                    {grossSalary.baseSalary > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((grossSalary.baseSalary * 1000) / 12) /
                            1000}
                        </Text>
                      </View>
                    ) : null}
                    {grossSalary.transportAllowance > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round(grossSalary.transportAllowance * 1000) /
                            1000}
                        </Text>
                      </View>
                    ) : null}
                    {grossSalary.attendanceBonus > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round(grossSalary.attendanceBonus * 1000) /
                            1000}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={styles.total}>
                  <View style={styles.totalItem}>
                    <Text style={styles.tableItemHeaderText}>
                      Total Salaire Brut (A)
                    </Text>
                  </View>
                  <View style={styles.totalItemValue}>
                    <Text style={styles.tableItemText}>
                      {Math.round((grossSalary.brutSalary / 12) * 1000) / 1000}
                    </Text>
                  </View>
                </View>
                <View style={styles.spacer} />
                <Text style={styles.leftHeader}>RETENUES SUR SALAIRE</Text>
                <View style={styles.table}>
                  <View style={styles.column}>
                    <View style={styles.tableItem}>
                      <Text style={styles.tableItemHeaderText}>
                        Désignation
                      </Text>
                    </View>
                    {pd.cs > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>CNSS</Text>
                      </View>
                    ) : null}
                    {pd.cavis > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>CAVIS</Text>
                      </View>
                    ) : null}
                    {pd.irpp > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>IRPP</Text>
                      </View>
                    ) : null}
                    {pd.css > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>CSS</Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemCenter}>
                      <Text style={styles.tableItemHeaderText}>Base</Text>
                    </View>
                    {pd.cs > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.csBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {pd.cavis > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.cavisBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {pd.irpp > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.irppBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {pd.css > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.cssBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemCenter}>
                      <Text style={styles.tableItemHeaderText}>Taux</Text>
                    </View>
                    {pd.cs > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round(pd.csRate * 100000) / 1000}%
                        </Text>
                      </View>
                    ) : null}
                    {pd.cavis > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round(pd.cavisRate * 100000) / 1000}%
                        </Text>
                      </View>
                    ) : null}
                    {pd.irpp > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>Barème</Text>
                      </View>
                    ) : null}
                    {pd.css > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {pd.cssRate * 100}%
                        </Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemHeaderValue}>
                      <Text style={styles.tableItemHeaderValueText}>
                        Montant
                      </Text>
                    </View>
                    {pd.cs > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.cs / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {pd.cavis > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.cavis / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {pd.irpp > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.irpp / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {pd.css > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((pd.css / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={styles.table}>
                  <View style={styles.column}>
                    {pd.insuranceGroup > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>
                          QP Assurance Groupe
                        </Text>
                      </View>
                    ) : null}
                    {pd.paydayAdvanceDeduction > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>
                          Retenue avance sur salaire
                        </Text>
                      </View>
                    ) : null}
                    {pd.loanHoldback > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>
                          Retenue sur prêt
                        </Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    {pd.insuranceGroup > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {pd.insuranceGroup}
                        </Text>
                      </View>
                    ) : null}
                    {pd.paydayAdvanceDeduction > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {pd.paydayAdvanceDeduction}
                        </Text>
                      </View>
                    ) : null}
                    {pd.loanHoldback > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {pd.loanHoldback}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={styles.total}>
                  <View style={styles.totalItem}>
                    <Text style={styles.tableItemHeaderText}>
                      Total Retenues sur salaire (B)
                    </Text>
                  </View>
                  <View style={styles.totalItemValue}>
                    <Text style={styles.tableItemText}>
                      {Math.round((pd.totalPayrollDeductions / 12) * 1000) /
                        1000}
                    </Text>
                  </View>
                </View>
                <View style={styles.totalGrey}>
                  <View style={styles.totalItem}>
                    <Text style={styles.tableItemHeaderText}>
                      SALAIRE NET A PAYER (A) - (B)
                    </Text>
                  </View>
                  <View style={styles.totalItemValue}>
                    <Text style={styles.tableItemText}>
                      {Math.round((net / 12) * 1000) / 1000}
                    </Text>
                  </View>
                </View>
                <View style={styles.spacer} />
                <Text style={styles.leftHeader}>CHARGES PATRONALES</Text>
                <View style={styles.table}>
                  <View style={styles.column}>
                    <View style={styles.tableItem}>
                      <Text style={styles.tableItemHeaderText}>
                        Désignation
                      </Text>
                    </View>
                    {ec.cs > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>CNSS</Text>
                      </View>
                    ) : null}
                    {ec.cavis > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>CAVIS</Text>
                      </View>
                    ) : null}
                    {ec.at > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>AT</Text>
                      </View>
                    ) : null}
                    {ec.tfp > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>TFP</Text>
                      </View>
                    ) : null}
                    {ec.forprolos > 0 ? (
                      <View style={styles.tableItem}>
                        <Text style={styles.tableItemText}>FOPROLOS</Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemCenter}>
                      <Text style={styles.tableItemHeaderText}>Base</Text>
                    </View>
                    {ec.cs > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.csBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.cavis > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.cavisBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.at > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.atBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.tfp > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.tfpBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.forprolos > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.forprolosBase / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemCenter}>
                      <Text style={styles.tableItemHeaderText}>Taux</Text>
                    </View>
                    {ec.cs > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {ec.csRate * 100}%
                        </Text>
                      </View>
                    ) : null}
                    {ec.cavis > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {ec.cavisRate * 100}%
                        </Text>
                      </View>
                    ) : null}
                    {ec.at > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {ec.atRate * 100}%
                        </Text>
                      </View>
                    ) : null}
                    {ec.tfp > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {ec.tfpRate * 100}%
                        </Text>
                      </View>
                    ) : null}
                    {ec.forprolos > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {ec.forprolosRate * 100}%
                        </Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.column}>
                    <View style={styles.tableItemHeaderValue}>
                      <Text style={styles.tableItemHeaderValueText}>
                        Montant
                      </Text>
                    </View>
                    {ec.cs > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.cs / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.cavis > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.cavis / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.at > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.at / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.tfp > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.tfp / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                    {ec.forprolos > 0 ? (
                      <View style={styles.tableItemRight}>
                        <Text style={styles.tableItemText}>
                          {Math.round((ec.forprolos / 12) * 1000) / 1000}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={styles.total}>
                  <View style={styles.totalItem}>
                    <Text style={styles.tableItemHeaderText}>
                      Total Charges patronales (C)
                    </Text>
                  </View>
                  <View style={styles.totalItemValue}>
                    <Text style={styles.tableItemText}>
                      {Math.round((ec.totalEmployerCharges / 12) * 1000) / 1000}
                    </Text>
                  </View>
                </View>
                <View style={styles.totalGrey}>
                  <View style={styles.totalItem}>
                    <Text style={styles.tableItemHeaderText}>
                      COUT SALARIE (A) + (C)
                    </Text>
                  </View>
                  <View style={styles.totalItemValue}>
                    <Text style={styles.tableItemText}>
                      {Math.round(
                        (grossSalary.brutSalary / 12 +
                          ec.totalEmployerCharges / 12) *
                          1000
                      ) / 1000}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.right}>
                <View style={styles.itemFullname}>
                  <Text style={styles.itemHeader}>NOM & PRENOM</Text>
                  <Text style={styles.itemContent}>
                    {employee.firstName} {employee.lastName}
                  </Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>MATRICULE INTERNE</Text>
                  <Text style={styles.itemContent}>{employee.internalId}</Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>MATRICULE CNSS</Text>
                  <Text style={styles.itemContent}>{employee.csId}</Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>N° CIN</Text>
                  <Text style={styles.itemContent}>{employee.cinId}</Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>CENTRE DE COUT</Text>
                  <Text style={styles.itemContent}>
                    {employee.centerCostId}
                  </Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>FONCTION</Text>
                  <Text style={styles.itemContent}>{employee.jobTitle}</Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>SITUATION FAMILIALE</Text>
                  <Text style={styles.itemContent}>{employee.maried}</Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>
                    ENFANT{employee.children > 0 ? "S" : null} À CHARGE
                  </Text>
                  <Text style={styles.itemContent}>{employee.children}</Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>SOLDE DE CONGE</Text>
                  <Text style={styles.itemContent}>
                    {employee.leaveBalance} jours
                  </Text>
                </View>
                <View style={styles.item}>
                  <Text style={styles.itemHeader}>MODE DE PAIEMENT</Text>
                  <Text style={styles.itemContent}>
                    {employee.paymentMethod}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  employee: state.pdf.employee,
  ec: state.pdf.ec,
  pd: state.pdf.pd,
  grossSalary: state.pdf.grossSalary,
  net: state.pdf.net,
  employer: state.pdf.employer,
  period: state.pdf.period,
  nbrDays: state.pdf.nbrDays,
  nbrMonth: state.pdf.nbrMonth,
});

export default connect(mapStateToProps)(PDF);
