import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { RootState } from "../app/store";

interface IProps {
  irppBase: number;
  irpp: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {}

class Impositions extends React.Component<IProps, IState> {
  render() {
    const { irppBase, irpp } = this.props;

    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row">
            IRPP ({irppBase})
          </TableCell>
          <TableCell />
          <TableCell component="th" scope="row" align="right">
            <NumberFormat
              value={Math.round((irpp / 12) * 1000) / 1000}
              displayType="text"
              thousandSeparator
              prefix="-"
              decimalScale={3}
              fixedDecimalScale
            />
          </TableCell>
          <TableCell component="th" scope="row" align="right">
            <NumberFormat
              value={Math.round(irpp * 1000) / 1000}
              displayType="text"
              thousandSeparator
              prefix="-"
              decimalScale={3}
              fixedDecimalScale
            />
          </TableCell>
        </TableRow>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  irppBase: state.pdf.pd.irppBase,
  irpp: state.pdf.pd.irpp,
});

export default connect(mapState)(Impositions);
